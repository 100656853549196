<template>
  <div class="header">
    <!-- Start Top Bar -->
    <div class="top-bar">
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-sm-6">
            <!-- Start Contact Info -->
            <!-- <ul class="contact-details">
              <li>
                <a href="#">
                  <i class="icon-location-pin"> </i>
                  8b Briton Street, LE3 0AA, Leicester, United Kingdom
                </a>
              </li>
            </ul> -->
            <!-- End Contact Info -->
          </div>
          <div class="col-md-5 col-sm-6">
            <!-- Start Social Links -->
            <ul class="social-list">
              <li>
                <a href="javascript:void(0);"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="javascript:void(0);"><i class="fa fa-twitter"></i></a>
              </li>
            </ul>
            <!-- End Social Links -->
          </div>
        </div>
      </div>
    </div>
    <!-- End Top Bar -->

    <!-- Start bottom Bar -->
    <div class="top-bar-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4">
            <div class="header-logo">
              <router-link to="/" class="no-active-style" :exact="true">
                <img
                  src="/assets/img/logos/asamai_properties_ltd_logo_004.png"
                  alt="ASAMAI PROPERTIES LOGO"
                />
              </router-link>
            </div>
          </div>
          <div class="col-md-4 col-sm-4">
            <div class="contact">
              <div class="call">
                <h5><i class="icon-screen-tablet"></i> 0772 336 3184</h5>
                <h5><i class="icon-phone"></i> 0116 296 4482</h5>
                <h5>
                  <i class="icon-envelope"></i> info@asamaiproperties.co.uk
                </h5>
              </div>
              <div class="clear"></div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4">
            <div class="contact">
              <div class="call">
                <router-link
                  class="btn btn-common app-valuation-btn btn-lg"
                  to="/valuation-request"
                  >REQUEST VALUATION</router-link
                >
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Bottom Bar -->

    <!-- Start  Logo & Naviagtion  -->
    <nav class="navbar navbar-default">
      <div class="container">
        <div class="row">
          <div class="navbar-header">
            <!-- Stat Toggle Nav Link For Mobiles -->
            <button
              type="button"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <i class="fa fa-bars"></i>
            </button>
            <div class="logo-wrapper hidden">
              <router-link
                class="navbar-brand no-active-style"
                to="/"
                :exact="true"
              >
                <img
                  src="/assets/img/logos/asamai_properties_ltd_logo_004.png"
                  alt="ASAMAI PROPERTIES LOGO"
              /></router-link>
            </div>
          </div>
          <div class="navbar-collapse collapse">
            <!-- Start Navigation List -->
            <ul class="nav navbar-nav">
              <li>
                <router-link to="/" :exact="true">Home</router-link>
              </li>
              <li>
                <router-link to="/about-us">About us</router-link>
              </li>
              <li>
                <router-link to="/service-business">What we do</router-link>
              </li>
              <li>
                <router-link to="/property-for-sale">Buy</router-link>
              </li>
              <li>
                <router-link to="/property-for-rent">Rent</router-link>
              </li>
              <li>
                <router-link to="/service-landlords">Landlords</router-link>
              </li>
              <li>
                <router-link to="/service-tenants">Tenants</router-link>
              </li>
              <li>
                <a href="#fotter-main">
                  Contact Us
                </a>
              </li>
              <li v-show="isAdmin || isDataEntry">
                <a href="javascript:void(0);">
                  Admin
                </a>
                <ul class="dropdown">
                  <li>
                    <router-link to="/admin-property"
                      >Manage Property</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/admin-locality"
                      >Manage Locality</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/admin-city">Manage City</router-link>
                  </li>
                </ul>
              </li>
            </ul>
            <!-- End Navigation List -->
          </div>
        </div>
      </div>
      <!-- End Header Logo & Naviagtion -->

      <!-- Mobile Menu Start -->
      <ul class="mobile-menu">
        <li>
          <router-link to="/" :exact="true">Home</router-link>
        </li>
        <li>
          <router-link to="/about-us">About us</router-link>
        </li>
        <li>
          <router-link to="/service-business">What we do</router-link>
        </li>
        <li>
          <router-link to="/property-for-sale">Buy</router-link>
        </li>
        <li>
          <router-link to="/property-for-rent">Rent</router-link>
        </li>
        <li>
          <router-link to="/service-landlords">Landlords</router-link>
        </li>
        <li>
          <router-link to="/service-tenants">Tenants</router-link>
        </li>
        <li>
          <router-link to="/valuation-request">Request valuation</router-link>
        </li>
        <li>
          <a href="#fotter-main">
            Contact us
          </a>
        </li>
        <li v-show="isAdmin || isDataEntry">
          <a href="javascript:void(0);">
            Admin
          </a>
          <ul class="dropdown">
            <li>
              <router-link to="/admin-property">Manage Property</router-link>
            </li>
            <li>
              <router-link to="/admin-locality">Manage Locality</router-link>
            </li>
            <li>
              <router-link to="/admin-city">Manage City</router-link>
            </li>
          </ul>
        </li>
      </ul>
      <!-- Mobile Menu End -->
    </nav>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["isAdmin", "isDataEntry"])
  },
  mounted() {
    window.$(".nav > li:has(ul)").addClass("drop");
    window.$(".nav > li.drop > ul").addClass("dropdown");
    window.$(".nav > li.drop > ul.dropdown ul").addClass("sup-dropdown");

    window.$(".mobile-menu").slicknav({
      prependTo: ".navbar-header",
      parentTag: "liner",
      allowParentLinks: true,
      duplicate: true,
      label: "",
      closedSymbol: '<i class="fa fa-angle-right"></i>',
      openedSymbol: '<i class="fa fa-angle-down"></i>'
    });
  }
};
</script>
